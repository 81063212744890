.App {
  text-align: center;
  color: white;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

a {
  text-decoration: none;
  color: white;
}

.continer {
  position: absolute;
  z-index: 999;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 40%;
}

h1 {
  padding: 0;
  margin: 0;
  border: 0;
  font: Roboto;
  text-shadow: 5px 5px 1px #000;
}

h2 {
  
}

h3 {
  background-color: goldenrod;
  color: black;
  display: block;
  line-height: 30px;
  padding: 1px 1px;}

h4 {
  letter-spacing: 10px;
  color: gold;
  }

ol {
  list-style-type: none;
  padding: 0;
}

.App-logo {
  color: gold;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {  
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 10px;
}

.App-link {
  color: #61dafb;
}



@media screen and (max-width: 1200px) {
  .video {
    height: 100%;
    z-index: -10;
    position: fixed;
    top: 0;
    left: 0;
  }
  
}

@media screen and (min-width: 1199px) {
  .video {
    width: 100%;
    height: auto;
    z-index: -10;
    position: fixed;
    top: 0;
    left: 0;
  }
}

@media only screen and (max-width: 600px) {

  .video {
    height: 100vh;
    z-index: -10;
    position: absolute;
    top: 0;
    left: 0;
  }
  
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
